import ClipboardJS from "clipboard";
import { ElNotification, ElLoading, ElMessage } from "element-plus";
interface Loading {
  active?: boolean;
  msg?: string;
}
type StringType = "error" | "success" | "warning" | "info";
interface Notification {
  title?: string;
  message: string;
  type?: StringType;
  duration?: number;
}

const mostrarNotificacion = ({
  title = "Error",
  message,
  type = "error",
  duration = 0,
}: Notification): void => {
  const clipboard = new ClipboardJS("#copiar_boton", {
    text: () => message,
  });
  ElNotification({
    customClass: "alerta-notificacion",
    title,
    message: `<div>
                <p>${message}</p><br/>
                <button id="copiar_boton" class="el-button el-button--primary el-button--small">
                  Copiar mensaje al Portapapeles
                </button>
              </div>`,
    type,
    dangerouslyUseHTMLString: true,
    duration: type == "success" ? 5000 : duration,
  });

  clipboard.on("success", () => {
    ElMessage({
      message: "El mensaje fue copiado.",
      type: "success",
    });
  });

  clipboard.on("error", () => {
    ElMessage({
      message: "No se pudo copiar el mensaje.",
      type: "warning",
    });
  });
};

const mostrarCargando = ({ active = true, msg }: Loading): void => {
  const loading = ElLoading.service({
    lock: true,
    text: msg,
    background: "rgba(0,0,0,0.7)",
  });

  if (!active) loading.close();
};

export { mostrarNotificacion, mostrarCargando };
