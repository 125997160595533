export interface IBase {
  activo?: boolean;
  creado?: string;
  actualizado?: string;
  creado_por?: string;
  actualizado_por?: string;
  cliente_id?: number;
  sede_id?: number | null;
}

export type ListasEstandarFormulario = Record<
  "codigo" | "nombre",
  string | boolean | number
>;

export enum ITiposDocumentosBaseEnum {
  OrdenCargue = "OC",
  RemesaNacional = "RN",
  Anticipo = "AP",
  Manifiesto = "MAN",
  ServicioOtroProveedor = "SOP",
  CuentaPorPagar = "CXP",
  NotaCreditoCxP = "NCP",
  NotaCreditoCxC = "NCC",
  NotaDebitoCxC = "DCC",
  NotaDebitoCxP = "DCP",
  RemesaMunicipal = "RM",
  ViajeMunicipal = "VM",
  CuentaPorCobrar = "CXC",
  NotaContable = "NCO",
  Egresos = "EG",
  Recaudos = "RC",
  Asignacion = "ASG",
  SolicitudFacturacion = "SF",
  SolicitudLiquidacion = "SL",
  Gasto = "RG",
  DocumentoSoporte = "DS",
  DistribucionIngreso = "DI",
  Recepcion = "REC",
  Entrega = "ENT",
}

export enum SecuenciaConsultaEnum {
  TODOS = 'true',
  PENDIENTES = 'false'
}

export interface ITipoDocumento {
  nombre: string;
  tipo_documento_id?: number;
  usa_documento_referencia: boolean;
  documento_referencia_obligatorio: boolean;
  usa_motivo_nota: boolean;
  usa_documento_relacionado: boolean;
  usa_comercial: boolean;
  valido_hasta?: string;
  tipo_base?: string;
  codigo_fe?: string;
  valor_maximo?: number;
  nota?: string;
  nota1?: string;
  nota2?: string;
  nota3?: string;
  nota4?: string;
  secuencia_id: number | null;
  activo: boolean;
}

export type ListaCompuesta = ListasEstandarFormulario & {
  deshabilitar: boolean;
};

export interface IOpcionBuscador {
  clave: string;
  valor: string;
}
export interface IOpcionBuscadorDoc {
  documento: string;
  documento_referencia: string;
  tercero_id: number | null;
  fecha_inicio: string;
  fecha_fin: string;
  estado: string;
}

export enum NivelRiesgoEnum {
  Bajo = "1",
  Medio = "2",
  Alto = "3",
}

export interface IGraficoDashboard<T> {
  titulo: string;
  opciones: {
    sub_etiquetas?: string[];
    etiquetas: string[];
    valores: T;
  };
}
